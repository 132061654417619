export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return

  const { setupIncomingBusinessUnit, setupIncomingService } = useAppointment()

  if (to.params.step === '3' && to.query?.serviceUuid) {
    if (!(await setupIncomingService(to.query.serviceUuid as string))) return navigateTo('/appointment/new/1')
    return
  }

  if (to.params.step === '2' && to.query?.businessUnitUuid) {
    if (!(await setupIncomingBusinessUnit(to.query.businessUnitUuid as string))) return navigateTo('/appointment/new/1')
    return
  }

  if (to.params.step === '1') return
  if (['2', '3', '4'].includes(to.params.step as string)) return navigateTo('/appointment/new/1')
  return navigateTo('/')
})
